import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Description, ServiceTitle } from '../../utils/commonStyles';
import { format } from 'date-fns';
import { colors } from '../../utils/device';

export default function RadioButtonTime({ options, setValue, value }) {


    return (<FormControl style={{ width: '100%' }}>
        <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-time-radio-buttons-group"
            value={value}
            style={{ width: '100%' }}
            onChange={({ target: { value } }) => setValue(value)}>
            {
                options.map((option, index) => <div key={`option-${option.id}`} style={{ marginLeft: '20px' }}>
                    <FormControlLabel value={option.id} control={<Radio />} label={<ServiceTitle style={{ color: value === option.id ? '#000000' : colors.greyText, fontWeight: value === option.id ? '600' : '500' }}> {format(new Date(option.appointmentDate), 'hh:mm a')}</ServiceTitle>} />
                </div>)
            }
        </RadioGroup>
    </FormControl>)
}