import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import { Body, BodyPadding, Description, SectionTitle, ServiceTitle, Subtitle } from "../../utils/commonStyles";
import { Button, Paper, TextField } from "@mui/material";
import { useFetchBusinessQuery, useFetchUserQuery } from "../../utils/redux/reducers.js/signUp";
import { setBusiness } from "../../utils/redux/reducers.js/business";
import { setUser } from "../../utils/redux/reducers.js/user";
import Loading from "../../Components/Loading";
import CustomizedSnackbars from "../../Components/SnackBar";
import { useFetchServiceQuery } from "../../utils/redux/reducers.js/services";
import { getUrlParams, getServiceTime, getServiceName } from '../../utils/common'
import RadioButtonsGroup from "../../Components/RadioButton";
import CalendarBlock from "../../Components/CalendarBlock";
import { useFetchAppointmentsQuery, useScheduleAppointmentmentMutation } from "../../utils/redux/reducers.js/appointments";
import { format, isAfter, isSameDay } from "date-fns";
import RadioButtonTime from "../../Components/RadioButtonTime";
import InputBlock from "../../Components/InputBlock";
import BasicTabs from "../../Components/Tabs";
import { emailValidationRegex } from "../../utils/constants";
import { colors } from "../../utils/device";

import RoomServiceRoundedIcon from '@mui/icons-material/RoomServiceRounded';
import HomeRepairServiceRoundedIcon from '@mui/icons-material/HomeRepairServiceRounded';
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import { useAddCustomerMutation } from "../../utils/redux/reducers.js/customers";
import { getAuth, isSignInWithEmailLink, onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailLink } from "firebase/auth";
import { app } from '../../firebase-config'
import { setToken } from "../../utils/redux/reducers.js/auth";
import PaymentBlock from "../../Components/PaymentBlock";

const auth = getAuth(app);

const Dashboard = () => {
    const token = useSelector((state) => state.auth.value)

    const [loading, setLoading] = useState(false)
    const [loadingAuth, setLoadingAuth] = useState(false)
    const [fieldError, setFieldError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')
    const [chosenService, setChosenService] = useState('')
    const [chosenDate, setChosenDate] = useState()
    const [chosenAppointment, setChosenAppointment] = useState('')
    const [price, setPrice] = useState(0)
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')

    const [step, setStep] = useState(0)
    const [mode, setMode] = useState('SignUp')

    const dispatch = useDispatch()

    const params = getUrlParams()

    const mId = params && params.mId
    const userId = params && params.userId

    const { data: serviceData, error: serviceError, isLoading: serviceLoading } = useFetchServiceQuery(userId ? `${mId}&userId=${userId}` : mId, { skip: !mId })
    const { data: businessData, error: businessError, isLoading: businessLoading } = useFetchBusinessQuery(mId, { skip: !mId })
    const { data: appointmentData, error: appointmentError, isLoading: appointmentLoading } = useFetchAppointmentsQuery(mId, { skip: !mId })
    const [scheduleAppointmentment, { isLoading: scheduleAppointmentLoading, error: scheduleAppointmentError, data: scheduleAppointmentmentData }] = useScheduleAppointmentmentMutation()
    const [addCustomer, { isLoading: addCustomerLoading, error: addCustomerError, data: addCustomerData }] = useAddCustomerMutation()

    const business = businessData && businessData.data
    const services = serviceData ? serviceData.data : []
    const appointments = appointmentData ? appointmentData.data : []

    useEffect(() => {
        if (!mId) {
            setErrorMessage('Invalid Url. Require merchant Id. Try again.')
        }
    }, [mId])

    useEffect(() => {
        if (token && business && services && appointments) {
            !chosenAppointment && checkIfParamsOfSessionExist()
        }
    }, [token, business, services, appointments])


    useEffect(() => {
        checkUserAuthenticated()
        checkIfAuthenticationLinkExists()
    }, [])


    const checkUserAuthenticated = () => {
        onAuthStateChanged(auth, user => {
            if (user) {
                auth.currentUser.getIdToken().then(idToken => {
                    // console.log('idToken', idToken)
                    dispatch(setToken(idToken))
                    setLoadingAuth(false)
                }).catch(e => {
                    console.error('login error', e)
                    setLoadingAuth(false)
                })
            }
        })
    }

    useEffect(() => {
        if (businessError) {
            setLoading(false)
            setErrorMessage(businessError ? (businessError.error || businessError.data.err) : businessError)
        }
    }, [businessError])

    useEffect(() => {
        if (serviceError) {
            setLoading(false)
            setErrorMessage(serviceError ? (serviceError.error || serviceError.data.err) : serviceError)
        }
    }, [serviceError])

    useEffect(() => {
        if (addCustomerError) {
            setLoading(false)
            setErrorMessage(addCustomerError ? (addCustomerError.error || addCustomerError.data.err) : addCustomerError)
        }
    }, [addCustomerError])

    useEffect(() => {
        if (scheduleAppointmentError) {
            setLoading(false)
            setErrorMessage(scheduleAppointmentError ? (scheduleAppointmentError.error || scheduleAppointmentError.data.err) : scheduleAppointmentError)
        }
    }, [scheduleAppointmentError])

    // useEffect(() => {
    //     if (chosenDate) {
    //         setChosenAppointment('')
    //     }
    // }, [chosenDate])

    // useEffect(() => {
    //     if (chosenService) {
    //         setChosenDate('')
    //         setChosenAppointment('')
    //     }
    // }, [chosenService])



    const paymentIntent = scheduleAppointmentmentData && scheduleAppointmentmentData.data

    useEffect(() => {
        if (paymentIntent) {
            setStep(step + 1)
        }
    }, [paymentIntent])

    const handleMoveStep = (back) => {
        if (back) {
            setStep(step - 1)
            return
        }
        if (step === 0) {
            if (!chosenService) {
                setErrorMessage('Please choose a service.')
                return
            }
            setStep(step + 1)
        } else if (step === 1) {
            if (!chosenDate) {
                setErrorMessage('Please choose a date for the service.')
                return
            }
            if (!chosenAppointment) {
                setErrorMessage('Please choose a time for the service.')
                return
            }
            setStep(step + 1)
        } else if (step === 2) {

            setFieldError('')
            setErrorMessage('')

            if (!token) {
                if (mode === 'SignUp') {
                    if (!firstName) {
                        setFieldError('first-name')
                        setErrorMessage('First name is required.')
                        return
                    }
                    if (!lastName) {
                        setFieldError('last-name')
                        setErrorMessage('Last name is required.')
                        return
                    }
                }

                if (!email) {
                    setFieldError('email')
                    setErrorMessage('Email is required.')
                    return
                }

                if (!email.match(emailValidationRegex)) {
                    setFieldError('email')
                    setErrorMessage('Invalid email.')
                    return
                }

                if (mode === 'SignUp') {
                    if (!phone) {
                        setFieldError('phone')
                        setErrorMessage('Phone is required.')
                        return
                    }
                    if (phone.length !== 9 || phone[0] !== '4') {
                        setFieldError('phone')
                        setErrorMessage('Invalid Phone number.')
                        return
                    }
                }
                addCustomer({ mId, data: { mode, firstName, lastName, email, phone } })
                setLoading(true)
            } else {
                // User is logged in.
                scheduleAppointmentment({ mId, data: { token, chosenAppointment } })
            }
        }
    }

    useEffect(() => {
        if (addCustomerData) {
            setLoading(false)
            sendEmailVerification()
        }
    }, [addCustomerData])

    const matchingAppointments = chosenService && appointments && appointments.filter(({ appointmentType, appointmentDate }) => appointmentType === chosenService && isAfter(new Date(appointmentDate), new Date()))

    const timingsOfChosenDate = matchingAppointments && matchingAppointments.filter(({ appointmentDate }) => isSameDay(new Date(appointmentDate), chosenDate)).map(({ id, appointmentDate, endAppointmentDate }) => ({ id, appointmentDate, endAppointmentDate }))

    const checkIfParamsOfSessionExist = () => {
        let index = 0
        if (params.chosenService) {
            const matchingService = services.find(({ id }) => id === params.chosenService)
            if (matchingService) {
                setPrice(matchingService.price)
                setChosenService(params.chosenService)
                index += 1
            } else {
                setStep(0)
                return
            }
        }
        if (params.chosenAppointment) {
            const matchingAppointment = appointments.find(({ id }) => id === params.chosenAppointment)
            setChosenAppointment(matchingAppointment.id)
            setChosenDate(new Date(matchingAppointment.appointmentDate))
            index += 1
        }
        setStep(index)
    }

    const tabOptions = ['SignUp', 'Login']

    const websiteFormatter = (url) => url.includes('localhost') ? `http://${url}` : `https://${url}`

    const sendEmailVerification = async () => {
        try {

            const actionCodeSettings = {
                url: websiteFormatter(window.location.host) + `?mId=${mId}&chosenAppointment=${chosenAppointment}&chosenService=${chosenService}`,
                handleCodeInApp: true
            };
            setLoadingAuth(true)
            window.localStorage.setItem('emailForSignIn', email);
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            setLoadingAuth(false)
            setSuccessMessage('Authentication email Sent. Click on the link in the email to be logged in.')
        } catch (e) {
            console.log(e)
            setLoadingAuth(false)
            setErrorMessage('Error in sending verification email.')
        }
    }

    const checkIfAuthenticationLinkExists = async () => {

        try {
            if (isSignInWithEmailLink(auth, window.location.href)) {

                let emailFromstorage = window.localStorage.getItem('emailForSignIn');
                if (!emailFromstorage) {
                    emailFromstorage = window.prompt('Please provide your email for confirmation');
                }
                setLoadingAuth(true)
                await signInWithEmailLink(auth, emailFromstorage, window.location.href);
                window.localStorage.removeItem('emailForSignIn');
            }
        } catch (e) {
            console.error(e)
            setErrorMessage(e.message)
            setLoadingAuth(false)
        }

    }

    return (<Body>
        <Navbar />
        <BodyPadding>
            <Loading open={scheduleAppointmentLoading || businessLoading || addCustomerLoading || loadingAuth || loading || serviceLoading || appointmentLoading} />
            {errorMessage && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
            {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}

            {business && <Paper elevation={3} style={{ backgroundColor: 'white', borderRadius: '10px', padding: '20px 15px' }}>
                {step === 0 && <>
                    <SectionTitle style={{ color: colors.textBlue }}>{business.name}</SectionTitle>
                    <Description>{business.description}</Description>
                    <Subtitle style={{ marginBottom: 0 }}>Services</Subtitle>
                    {services && <RadioButtonsGroup options={services} value={chosenService} setValue={setChosenService} setPrice={setPrice} />}
                </>}

                {step === 1 &&
                    <>
                        <SectionTitle style={{ margin: 'auto', display: 'flex', justifyContent: 'center', color: colors.textBlue }}>Pick a date</SectionTitle>
                        <CalendarBlock matchingAppointments={matchingAppointments || []} chosenDate={chosenDate} setChosenDate={setChosenDate} />
                        {chosenDate && <>
                            <Subtitle style={{ fontWeight: '600' }}> Select a time for {format(chosenDate, 'dd/MMM/yyyy')}</Subtitle>
                            {/* <Subtitle style={{ marginBottom: 0, fontWeight: '600' }}>Timings:</Subtitle> */}
                            <RadioButtonTime options={timingsOfChosenDate} value={chosenAppointment} setValue={setChosenAppointment} />
                        </>}
                    </>
                }

                {step === 2 &&
                    <>

                        {!token && <>
                            <BasicTabs tabOptions={tabOptions} currentTab={tabOptions.findIndex(tab => tab === mode)} setCurrentTab={(index) => setMode(tabOptions[index])} customStyle={{ margin: 'auto', display: 'flex', width: '100%', justifyContent: 'center', marginBottom: '20px' }} />
                            <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', justifyContent: 'space-evenly' }}>
                                {mode === 'SignUp' && <>
                                    <InputBlock error={fieldError === 'first-name'} style={{ width: '250px' }} errorMessage={errorMessage} required label='First Name' id={'first-name'} value={firstName} setValue={setFirstName} />
                                    <InputBlock error={fieldError === 'last-name'} style={{ width: '250px' }} errorMessage={errorMessage} required label='Last Name' id={'last-name'} value={lastName} setValue={setLastName} />
                                </>}
                                <InputBlock error={fieldError === 'email'} style={{ width: '250px' }} type="email" errorMessage={errorMessage} required label='Email' id={'email'} value={email} setValue={setEmail} />
                                {mode === 'SignUp' && <InputBlock error={fieldError === 'phone'} style={{ width: '250px' }} placeholder={'4xxxxxxxx'} country="Australia" type="tel" errorMessage={errorMessage} required label='Phone' id={'phone'} value={phone} setValue={setPhone} />}
                            </div>
                            <Description style={{ textAlign: 'center', margin: 'auto', marginBottom: '5px', justifyContent: 'center' }}>No passwords required! You will receive an email with a link to login.</Description>
                        </>}

                        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', margin: 'auto', alignItems: 'center' }}>
                            <Subtitle style={{ marginBottom: 0, color: colors.textBlue }}>Booking details</Subtitle>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                                <HomeRepairServiceRoundedIcon style={{ color: colors.textBlue }} />
                                <ServiceTitle style={{ marginBottom: 0, marginTop: 0, marginLeft: '10px' }}>{getServiceName(services, chosenService)}</ServiceTitle>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                                <RoomServiceRoundedIcon style={{ color: colors.textBlue }} />
                                <ServiceTitle style={{ marginBottom: 0, marginTop: 0, marginLeft: '10px' }}>{getServiceTime(timingsOfChosenDate, chosenAppointment) ? format(new Date(getServiceTime(timingsOfChosenDate, chosenAppointment)), 'dd/MMM/yyyy hh:mm a') : ''}</ServiceTitle>
                            </div>
                            {business && business.locations.length > 0 &&
                                <div style={{ display: 'flex', alignItems: 'center', marginTop: 20 }}>
                                    <HomeRoundedIcon style={{ color: colors.textBlue }} />
                                    <ServiceTitle style={{ marginBottom: 0, marginTop: 0, marginLeft: '10px' }}>{business.locations[0].address}</ServiceTitle>
                                </div>
                            }
                        </div>
                    </>
                }

                {step === 3 && <>
                    <PaymentBlock clientSecret={paymentIntent} goBack={() => setStep(step - 1)} price={price} />
                </>}
                {step !== 3 && <>
                    <Button variant="contained" style={{ margin: '20px auto', display: 'flex', width: '60%' }} onClick={() => handleMoveStep(false)}>Continue</Button>
                    {step > 0 && <Button variant={"outlined"} style={{ margin: '20px auto', display: 'flex', width: '60%' }} onClick={() => handleMoveStep(true)}>Back</Button>}
                </>}
            </Paper>}
        </BodyPadding>
    </Body >)
}

export default Dashboard