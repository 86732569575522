import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Description, ServiceTitle } from '../../utils/commonStyles';
import { convertMinsToHours } from '../../utils/time';

export default function RadioButtonsGroup({ options, setValue, value, setPrice }) {
    return (
        <FormControl style={{ width: '100%' }}>
            <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                style={{ width: '100%' }}
                onChange={({ target: { value } }) => { setValue(value); setPrice(options.find(({ id }) => id === value).price) }}>
                {
                    options.map(option => <div key={option.id} style={{ marginLeft: '20px' }}>
                        <FormControlLabel value={option.id} control={<Radio />} label={<ServiceTitle> {option.name}</ServiceTitle>} />
                        {option.description.trim() && <Description style={{ marginTop: '-10px' }}> {option.description.trim()}</Description>}
                        <Description style={{ fontSize: '14px', marginTop: option.description.trim() ? 0 : '-10px', color: '#767676' }}> {convertMinsToHours(option.duration)} - ${option.price}</Description>
                    </div>)
                }
            </RadioGroup>
        </FormControl>
    );
}