import React, { useEffect } from "react";
import { Content, Wrapper } from "./styles";
import { colors } from "../../utils/device";
import { useDispatch, useSelector } from 'react-redux'
import { setBusiness } from "../../utils/redux/reducers.js/business";
import { setUser } from "../../utils/redux/reducers.js/user";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
    const token = useSelector((state) => state.auth.value)

    const navigate = useNavigate()

    // console.log('token', token)

    return (<Wrapper>
        <Content>
            <h1 style={{ display: 'flex', justifyContent: 'center', width: '100%', textAlign: 'center', fontSize: '23px' }}>Book A Service</h1>
        </Content>
    </Wrapper>)
}

export default Navbar