import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Components/Navbar";
import { Body, BodyPadding, PaperModified } from "../../utils/commonStyles";
import { setToken } from '../../utils/redux/reducers.js/auth'
import { Button, Paper, TextField } from "@mui/material";
import { getAuth, isSignInWithEmailLink, onAuthStateChanged, sendSignInLinkToEmail, signInWithEmailLink } from 'firebase/auth'
import { app } from '../../firebase-config'
import CustomizedSnackbars from "../../Components/SnackBar";
import InputBlock from "../../Components/InputBlock";
import { useNavigate } from "react-router-dom";
import { useSignUpUserMutation } from "../../utils/redux/reducers.js/signUp";
import Loading from "../../Components/Loading";
import BasicTabs from '../../Components/Tabs'
import { emailValidationRegex } from "../../utils/constants";

const auth = getAuth(app);

const Login = () => {
    const token = useSelector((state) => state.auth.value)

    const [page, setPage] = useState(1)

    const [fieldError, setFieldError] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const [loadingAuth, setLoadingAuth] = useState(false)

    const [successMessage, setSuccessMessage] = useState('')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')

    const [email, setEmail] = useState('')
    const navigate = useNavigate();
    const dispatch = useDispatch()

    useEffect(() => {
        checkUserAuthenticated()
        checkIfAuthenticationLinkExists()
    }, [])


    const checkUserAuthenticated = () => {
        onAuthStateChanged(auth, user => {
            if (user) {
                auth.currentUser.getIdToken().then(idToken => {
                    // console.log('idToken', idToken)
                    dispatch(setToken(idToken))
                    setLoadingAuth(false)
                    window.location.href = '/'
                }).catch(e => {
                    console.error('login error', e)
                    setLoadingAuth(false)
                })
            }
        })
    }


    const [addUser, { error: errorAdd, data: dataAdd, isLoading }] = useSignUpUserMutation()


    useEffect(() => {
        if (dataAdd) {
            sendEmailVerification()
        }
    }, [dataAdd])

    useEffect(() => {
        if (errorAdd) {
            setErrorMessage(errorAdd ? (errorAdd.err || errorAdd.error) : errorAdd)
        }
    }, [errorAdd])

    const handleSignIn = async () => {
        setFieldError('')
        setErrorMessage('')

        if (page === 'Sign Up') {
            if (!firstName) {
                setFieldError('first-name')
                setErrorMessage('First name is required.')
                return
            }

            if (!lastName) {
                setFieldError('last-name')
                setErrorMessage('Last name is required.')
                return
            }
        }

        if (!email) {
            setFieldError('email')
            setErrorMessage('Email is required.')
            return
        }

        if (!email.match(emailValidationRegex)) {
            setFieldError('email')
            setErrorMessage('Invalid email.')
            return
        }

        const payload = {
            firstName,
            lastName,
            email,
            role: 'owner'
        }

        addUser({
            data: payload
        })
    }

    const websiteFormatter = (url) => url.includes('localhost') ? `http://${url}` : `https://${url}`

    const sendEmailVerification = async () => {
        try {

            const actionCodeSettings = {
                url: websiteFormatter(window.location.host) + `/login`,
                handleCodeInApp: true
            };
            setLoadingAuth(true)
            window.localStorage.setItem('emailForSignIn', email);
            await sendSignInLinkToEmail(auth, email, actionCodeSettings);
            setLoadingAuth(false)
            setSuccessMessage('Authentication email Sent. Click on the link in the email to be logged in.')
        } catch (e) {
            setLoadingAuth(false)
            setErrorMessage('Error in sending verification email.')
        }
    }

    const checkIfAuthenticationLinkExists = async () => {

        try {
            if (isSignInWithEmailLink(auth, window.location.href)) {

                let emailFromstorage = window.localStorage.getItem('emailForSignIn');
                if (!emailFromstorage) {
                    emailFromstorage = window.prompt('Please provide your email for confirmation');
                }
                setLoadingAuth(true)
                await signInWithEmailLink(auth, emailFromstorage, window.location.href);
                window.localStorage.removeItem('emailForSignIn');
            }
        } catch (e) {
            console.error(e)
            setErrorMessage(e.message)
            setLoadingAuth(false)
        }

    }

    const tabOptions = ['Log In', 'Sign Up']

    return (<Body style={{ margin: 'auto', width: '80%', maxWidth: '800px' }}>
        <BodyPadding style={{ margin: 'auto', width: '100%' }}>
            <PaperModified elevation={3} style={{ backgroundColor: 'white', borderRadius: '10px', margin: 'auto', width: '80%', padding: '20px 15px' }}>
                {(isLoading || loadingAuth) && <Loading open={isLoading} />}

                {errorMessage && !fieldError && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
                {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}
                <BasicTabs tabOptions={tabOptions} currentTab={page} setCurrentTab={setPage} customStyle={{ marginBottom: '40px' }} />
                {
                    tabOptions[page] === 'Sign Up' && <>
                        <InputBlock error={fieldError === 'first-name'} errorMessage={errorMessage} required label='First Name' id={'first-name'} value={firstName} setValue={setFirstName} />
                        <InputBlock error={fieldError === 'last-name'} errorMessage={errorMessage} required label='Last Name' id={'last-name'} value={lastName} setValue={setLastName} />
                    </>
                }
                <InputBlock error={fieldError === 'email'} type="email" errorMessage={errorMessage} required label='Email' id={'email'} value={email} setValue={setEmail} />
                <Button variant='contained' onClick={() => handleSignIn()}>{tabOptions[page]}</Button>

            </PaperModified>
        </BodyPadding>
    </Body>)
}

export default Login