import React, { useState } from 'react'
import { getDay, isSameDay } from 'date-fns'
import { enGB } from 'date-fns/locale'
import { Calendar } from 'react-nice-dates'
import 'react-nice-dates/build/style.css'


export default function CalendarBlock({ matchingAppointments, chosenDate, setChosenDate }) {


  const modifiers = {
    selected: date => isSameDay(date, chosenDate),
    highlight: date => matchingAppointments.some(({ appointmentDate }) => isSameDay(new Date(appointmentDate), date)),
    disabled: date => !matchingAppointments.some(({ appointmentDate }) => isSameDay(new Date(appointmentDate), date)), // Disables Saturdays
  }

  const modifiersClassNames = {
    highlight: '-highlight'
  }

  const handleDayClick = date => {
    setChosenDate(date)
  }

  return (<Calendar onDayClick={handleDayClick} minimumDate={new Date()} modifiers={modifiers}
    locale={enGB} modifiersClassNames={modifiersClassNames} />)
}