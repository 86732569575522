// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCZCFTYAp2TLxdUuAU6o-oYHUnfrMdA0AA",
  authDomain: "bookaclub-customer.firebaseapp.com",
  projectId: "bookaclub-customer",
  storageBucket: "bookaclub-customer.appspot.com",
  messagingSenderId: "751974583129",
  appId: "1:751974583129:web:6081fb7456248edb541a48",
  measurementId: "G-Y7YC1W53XF"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export { analytics, app }