
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";

import Dashboard from './Pages/Dashboard';
import Login from './Pages/Login';
import Checkout from './Pages/Checkout';

const returnElement = (element) => <>
  {element}
</>
const router = createBrowserRouter([
  {
    path: "/",
    element: returnElement(<Dashboard />),
  },
  {
    path: "/login",
    element: returnElement(<Login />),
  },
  {
    path: "/checkout",
    element: returnElement(<Checkout />),
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  )
}

export default App;
