import React, { useState } from 'react';
import { useStripe, useElements, PaymentElement, Elements } from '@stripe/react-stripe-js';


import { loadStripe } from '@stripe/stripe-js';
import { stripePublishKey } from '../../utils/constants';
import { getUrlParams } from '../../utils/common';
import { Button } from '@mui/material';
import Loading from '../Loading';
import CustomizedSnackbars from '../SnackBar';
import { Description } from '../../utils/commonStyles';
const stripePromise = loadStripe(stripePublishKey)

const CheckoutForm = ({ goBack, price }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const params = getUrlParams()
    const mId = params && params.mId

    const handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setIsLoading(true)
        const { error } = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: window.location.host.includes('localhost') ? `http://${window.location.host}/checkout?mId=${mId}` : `https://${window.location.host}/checkout?mId=${mId}`
            },
        });


        if (error) {
            setIsLoading(false)
            // This point will only be reached if there is an immediate error when
            // confirming the payment. Show error to your customer (for example, payment
            // details incomplete)
            setErrorMessage(error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            setIsLoading(false)
            setSuccessMessage('Redirecting...')
        }
    };

    return (<form onSubmit={handleSubmit}>
        <Loading open={isLoading} />
        <Description>Price: A${price}</Description>
        {errorMessage && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
        {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}

        <PaymentElement />
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
            <Button variant='contained' disabled={!stripe} onClick={handleSubmit}>Submit</Button>
            <Button variant='outlined' style={{ marginTop: '20px' }} disabled={!stripe} onClick={goBack}>Back</Button>
        </div>
    </form>)
};


const PaymentBlock = ({ clientSecret, goBack, price }) => {

    const options = {
        // passing the client secret obtained in step 3
        clientSecret,
        // Fully customizable with appearance API.
        appearance: { theme: 'stripe' }
    };
    return (<Elements stripe={stripePromise} options={options}>
        <CheckoutForm goBack={goBack} price={price} />
    </Elements>)
}

export default PaymentBlock;