// export const serverUrl = 'http://localhost:8080/'
export const serverUrl = 'https://app-bookaclub-server-czhfygf2ia-km.a.run.app/'

export const emailValidationRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

// export const stripePublishKey = 'pk_test_51IAMCNGiXimZbfRIwo5POmcKHbw7rkHaFgSIIYa8Y2kRxMMGvkSNtoLdQzphteQjGsVniVqHT4BDOH28v8tEDOkR00A7uAE4jP'
export const stripePublishKey = 'pk_live_51NiFPsDD50ZJ2MkHazC1UMfHV0PyhwLdxYf83PUCiQLXYkIUcSFQD7wq5mHuvcPC4G5VLVShyfWDbJ3bcrivTrFU00u7LpTWrg'

export const dayIndexToDay = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
}