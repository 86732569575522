export const generateRandomString = (length = 30) => [...Array(length)].map(() => Math.random().toString(36)[2]).join('')

export const getUrlParams = () => {
    const url = window.location.href.split('?')[1]
    const urlParams = new URLSearchParams(url)
    const paramsObj = Object.fromEntries(urlParams)
    return paramsObj
}

export const getServiceName = (services, serviceId) => {
    const matchingService = services.find(({ id }) => id === serviceId)
    if (matchingService) {
        return matchingService.name
    }
    return 'Service doesnt exist'
}

export const getServiceTime = (timings, appointmentId) => {
    const matchingAppointment = timings.find(({ id }) => id === appointmentId)
    if (matchingAppointment) {
        return matchingAppointment.appointmentDate
    }
    return null
}