import styled, { css } from "styled-components";
import { colors, device } from "./device";
import { Paper } from "@mui/material";

export const Body = styled.div`
    overflow: hidden;
    vertical-align: top;
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100vh;
    flex-grow: 1;
    transition: all 0.3s ease 0s;
    margin-right: 0px;
    @media ${device.mobile}, ${device.tablet}{
        margin-left: 0;
    }
`

export const BodyPadding = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px;
    overflow: scroll;
    max-width: 700px;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
`

export const PaperModified = styled(Paper)`
    display: flex;
    flex-direction: column;
    padding: 20px;
    border-radius: 10px;
`

export const SectionTitle = styled.h1`
    display: flex;
    font-size: 24px;
    font-weight: 800;
`

export const Subtitle = styled.h3`
    display: flex;
    font-size: 18px;
    font-weight: 600;
`

export const ServiceTitle = styled.h4`
    display: flex;
    font-size: 16px;
    font-weight: 600;
`

export const Description = styled.p`
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    color: #39464e;
    margin-top: 0;
`