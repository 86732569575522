import React, { useState, useEffect } from 'react';
import { useStripe, Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { stripePublishKey } from '../../utils/constants';
import { useNavigate } from 'react-router-dom';
import { getUrlParams } from '../../utils/common';
import Loading from '../../Components/Loading';
import CustomizedSnackbars from '../../Components/SnackBar';
import { BodyPadding, Description } from '../../utils/commonStyles';
import { Paper } from '@mui/material';
const stripePromise = loadStripe(stripePublishKey)

const CheckoutForm = () => {
    const stripe = useStripe();
    const [message, setMessage] = useState(null);

    const [errorMessage, setErrorMessage] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showAppointmentConfirmed, setShowAppointmentConfirmed] = useState(false);

    const navigate = useNavigate()

    useEffect(() => {
        if (!stripe) {
            return;
        }

        // Retrieve the "payment_intent_client_secret" query parameter appended to
        // your return_url by Stripe.js
        const clientSecret = new URLSearchParams(window.location.search).get(
            'payment_intent_client_secret'
        );

        const params = getUrlParams()
        const mId = params && params.mId

        // Retrieve the PaymentIntent
        stripe
            .retrievePaymentIntent(clientSecret)
            .then(({ paymentIntent }) => {
                // Inspect the PaymentIntent `status` to indicate the status of the payment
                // to your customer.
                //
                // Some payment methods will [immediately succeed or fail][0] upon
                // confirmation, while others will first enter a `processing` state.
                //
                // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
                switch (paymentIntent.status) {
                    case 'succeeded':
                        setSuccessMessage('Success! Payment received.');
                        setShowAppointmentConfirmed(true)
                        break;

                    case 'processing':
                        setSuccessMessage("Payment processing. We'll update you when payment is received.");
                        setShowAppointmentConfirmed(true)
                        break;

                    case 'requires_payment_method':
                        // Redirect your user back to your payment page to attempt collecting
                        // payment again
                        setErrorMessage('Payment failed. Please try another payment method.');
                        navigate(`/?mId=${mId}`)
                        break;

                    default:
                        setErrorMessage('Something went wrong.');
                        break;
                }
            });
    }, [stripe]);


    return <>
        <Loading open={isLoading} />
        {errorMessage && <CustomizedSnackbars type={'error'} message={errorMessage} clearMessage={setErrorMessage} />}
        {successMessage && <CustomizedSnackbars type={'success'} message={successMessage} clearMessage={setSuccessMessage} />}

        {showAppointmentConfirmed && <BodyPadding>
            <Paper elevation={3} style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', backgroundColor: 'white', borderRadius: '10px', padding: '20px 15px' }}>
                <Description>Your appointment has been confirmed.</Description>
                <Description>You will be receiving an email shortly.</Description>
            </Paper>
        </BodyPadding>}
    </>
};


const Checkout = () => {

    return (<Elements stripe={stripePromise} options={{ appearance: { theme: 'stripe' } }}>
        <CheckoutForm />
    </Elements>)
}


export default Checkout